// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    recording: true,
    apiBaseURL: 'https://renaliq-comm-api-dev.azurewebsites.net/api/VideoCall/Acs/',
    // apiBaseURL:'https://localhost:5001/api/VideoCall/Acs/',
    baseURL: 'https://renaliq-comm-api-dev-web.azurewebsites.net/',
    gaTrackingId: "G-8TW4D47KR9",
    startRecordingAPI: 'StartRecording/',
    tokenAPI: 'GetAuthToken/',
    refreshTokenAPI: 'RefreshToken/',
    meetingInfoAPI: 'meeting/info/',
    statisticsAPI: 'statistics/save',
    feedback: '#/feedback',
    recordingStatusUpdateUrl: 'recording/status/',
    initSessionUrl: 'initSession/',
    patientRedirectUrl: 'https://my.somatus.com/',
    graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
    authClientID: 'ff07499a-f034-409c-8fec-ea1393946a12',
    authAuthority: 'https://login.microsoftonline.com/938f01d5-e195-4312-b7d5-3387273aadf9',
    authRedirectURI: 'https://localhost:4200',
    apiClientID: 'api://bcc623ab-d9eb-4141-a344-841f1ed1ba5e/app-hyrbid-scheduler-api',
    requestFormLink: 'https://forms.office.com/Pages/ResponsePage.aspx?id=2OFdye_MpUuwxDDwfTUsS1iXTAzFa-JLtxeVlq_khihUOFQ1NlJLVjBPRjdOQTg4N0hSRUFNS09TNiQlQCN0PWcu&amp;sid=4c893dbe-fe7d-4d05-aae7-1f26aafb0206',

};
