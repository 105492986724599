import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PatientConsentComponent } from './patient-consent/patient-consent.component';
import { HomeScreenComponent } from './video-call/home-screen/home-screen.component';
import { CallScreenComponent } from './video-call/call-screen/call-screen.component';
import { ErrorComponent } from './error/error/error.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginFailedComponent } from './auth/login-failed/login-failed.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: 'consent', component: PatientConsentComponent },
  { path: 'join/:userType/:meetingId', component: HomeScreenComponent},
  { path: 'call', component: CallScreenComponent},
  { path: 'error', component: ErrorComponent },
  //{ path: '**', redirectTo: '/error'}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true}),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
