<div class="">
    <div id="preview" 
      class="t-none" 
      #preview 
      [ngStyle]="{'display': isInitializing? 'block': 'none'}"
      style="background-position: center center; background-size: cover;">
    </div>
    <!-- <div [ngStyle]="{'display': !isInitializing? 'block': 'none'}" class="w-100">
        <svg viewBox="0 0 24 24" version="1.1" 
          xmlns="http://www.w3.org/2000/svg" 
          xmlns:xlink="http://www.w3.org/1999/xlink" 
          width="100%">
            <title>ic_fluent_person_24_filled</title>
            <desc>Created with Sketch.</desc>
            <g id="🔍-Product-Icons" stroke="none" 
              stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ic_fluent_person_24_filled" fill="#D7D7D7" fill-rule="nonzero">
                <path d="M17.7541747,13.999921 C18.9961948,13.999921 20.0030511,15.0067773 20.0030511,16.2487975 L20.0030511,17.1672553 C20.0030511,17.7406209 19.8238304,18.2996465 19.4904678,18.7661395 C17.9445793,20.9293884 15.4202806,22.0010712 12,22.0010712 C8.57903185,22.0010712 6.05606966,20.9289147 4.51390935,18.7645697 C4.18194679,18.2986691 4.00354153,17.7408416 4.00354153,17.1687745 L4.00354153,16.2487975 C4.00354153,15.0067773 5.0103978,13.999921 6.25241795,13.999921 L17.7541747,13.999921 Z M12,2.0046246 C14.7614237,2.0046246 17,4.24320085 17,7.0046246 C17,9.76604835 14.7614237,12.0046246 12,12.0046246 C9.23857625,12.0046246 7,9.76604835 7,7.0046246 C7,4.24320085 9.23857625,2.0046246 12,2.0046246 Z" id="🎨-Color"></path>
              </g>
            </g>
        </svg>
    </div> -->
</div>
