import { Injectable } from '@angular/core';
import { AudioDeviceInfo, Call, CallAgent, LocalVideoStream, RemoteParticipant, VideoDeviceInfo } from '@azure/communication-calling';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionState } from '../common/RemoteStreamSelector';
import { RecordingResponse } from '../models/Api';
import { MeetingInfo, ParticipantStream, UserDetails } from '../models/Participants';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    static instance: StorageService;
    private meetingIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private userDetailsSubject: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>({userName: '', userType: ''});
    private callAgentSubject: BehaviorSubject<CallAgent> = new BehaviorSubject<CallAgent>(null);
    private calls: Call;
    private callState: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public participants: BehaviorSubject<RemoteParticipant[]> = new BehaviorSubject<RemoteParticipant[]>([]);
    public participantsList: RemoteParticipant[] = [];
    private screenShareStreams: ParticipantStream[] = [];
    private localStream: BehaviorSubject<LocalVideoStream | undefined> = new BehaviorSubject<LocalVideoStream | undefined>(undefined);
    private videoDeviceInfo: VideoDeviceInfo;
    private audioDeviceInfo: AudioDeviceInfo;
    private _participantType: string = 'patient';
    private dominantParticipants: BehaviorSubject<SelectionState[]> = new BehaviorSubject<SelectionState[]>([])
    private meetingInfo$: BehaviorSubject<MeetingInfo> = new BehaviorSubject({} as MeetingInfo);
    private recordingInfo$: BehaviorSubject<RecordingResponse> = new BehaviorSubject({} as RecordingResponse);
    private communicationUserId: string;
    constructor() {
        StorageService.instance = this;
    }
    get participantType() {
        return this._participantType;
    }
    getUserDetails(): Observable<UserDetails> {
        return this.userDetailsSubject.asObservable();
    }
    setUserDetails(value: UserDetails): void {
        localStorage.setItem('renalIQVideoUI_userDetails', JSON.stringify(value));
        this.userDetailsSubject.next(value);
    }
    getMeetingId(): Observable<string> {
        return this.meetingIdSubject.asObservable();
    }
    setMeetingId(value: string): void {
        localStorage.setItem('renalIQVideoUI_meetingId', value);
        this.meetingIdSubject.next(value);
    }
    getCallAgent(): Observable<CallAgent> {
        return this.callAgentSubject.asObservable();
    }
    setCallAgent(value: CallAgent): void {
        this.callAgentSubject.next(value);
    }
    getCalls(): Call {
        return this.calls;
    }
    setCalls(value: Call): void {
        this.calls = value;
    }
    getParticipants():  Observable<RemoteParticipant[]> {
        return this.participants.asObservable();;
    }
    setParticipants(value: RemoteParticipant[]): void {
        this.participantsList = value;
        this.participants.next([...this.participantsList]);
    }
    getDominantParticipants():  Observable<SelectionState[]> {
        return this.dominantParticipants.asObservable();;
    }
    setDominantParticipants(value: SelectionState[]): void {
        this.dominantParticipants.next([...value]);
    }
    getLocalStream():  Observable<LocalVideoStream | undefined> {
        return this.localStream.asObservable();
    }
    setLocalStream(value: LocalVideoStream | undefined): void {
        this.localStream.next(value);
    }
    getVideoDeviceInfo(): VideoDeviceInfo {
        return this.videoDeviceInfo;
    }
    setVideoDeviceInfo(value: VideoDeviceInfo): void {
        this.videoDeviceInfo = value;
    }
    getAudioDeviceInfo(): AudioDeviceInfo {
        return this.audioDeviceInfo;
    }
    setAudioDeviceInfo(value: AudioDeviceInfo): void {
        this.audioDeviceInfo = value;
    }
    getCallState(): Observable<string> {
        return this.callState as Observable<string>;
    }
    setCallState(value: string): void {
        this.callState.next(value);
    }
    addScreenShareStream(stream, user): void {
        const newScreenShareStream: ParticipantStream = { stream, user };
        this.screenShareStreams = [ ...this.screenShareStreams, newScreenShareStream ];
    }
    removeScreenShareStream(streamVal, user): void {
        const screenShareStreams = this.screenShareStreams.filter(
            (stream) => stream.stream !== streamVal && stream.user !== user
        );
        this.screenShareStreams = screenShareStreams;
    }
    getScreenShareStreams(): ParticipantStream[] {
        return this.screenShareStreams;
    }
    getMeetingInfo(): Observable<MeetingInfo> {
        return this.meetingInfo$.asObservable();
    }
    setMeetingInfo(value: MeetingInfo): void {
        this.meetingInfo$.next(value);
    }
    getRecordingInfo(): Observable<RecordingResponse> {
        return this.recordingInfo$.asObservable();
    }
    setRecordingInfo(value: RecordingResponse): void {
        this.recordingInfo$.next(value);
    }
    getCommunicationUserId(): string {
        return this.communicationUserId;
    }
    setCommunicationUserId(value: string): void {
        this.communicationUserId = value;
    }
    getValueFromLocalStorage(prop: string) {
        return localStorage.getItem(prop)
    }
}