import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-device-setting',
  templateUrl: './device-setting.component.html',
  styleUrls: ['./device-setting.component.css']
})
export class DeviceSettingComponent implements OnInit {
    id: string;
    @Input() selectedId: string;
    @Input() label: string;
    @Input('devices') devices: any = [];
    @Output() settingsChanged = new EventEmitter<any>();
    constructor(){}
    ngOnInit(): void {
      
    }

    onSettingsChanged(deviceId: string) {
      const deviceInfo = this.devices.filter((item) => item._id === deviceId);
      this.settingsChanged.emit({deviceInfo: deviceInfo[0], label: this.label});
    }
}
