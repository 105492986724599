import { Component, ElementRef, ViewChild, Renderer2, Input } from '@angular/core';
import { RemoteVideoTrack } from 'twilio-video';
import { LocalVideoStream, VideoStreamRenderer, VideoStreamRendererView } from '@azure/communication-calling';

@Component({
    selector: 'camera',
    styleUrls: ['./camera-screen.component.css'],
    templateUrl: './camera-screen.component.html',
})
export class CameraScreenComponent {
    @ViewChild('preview') previewElement: ElementRef;
    private videoStreamRenderer: VideoStreamRenderer;
    private viewElement: VideoStreamRendererView;
    private localVideoStreamStore: LocalVideoStream | RemoteVideoTrack;
    isInitializing: boolean = false;
    @Input() set localVideoStream(streamValue: LocalVideoStream | RemoteVideoTrack) {
        this.isInitializing = false;
        this.startVideoStream(streamValue);
        this.localVideoStreamStore = streamValue;
    }
    constructor(private readonly renderer: Renderer2) { }
    private async startVideoStream(localCameraStream) {
        if(localCameraStream) {
            // if(this.viewElement && this.viewElement.target) {
            //     this.renderer.removeChild(this.previewElement.nativeElement, this.viewElement.target);
            // }
            if(this.localVideoStreamStore && this.videoStreamRenderer) {
                this.videoStreamRenderer.dispose();
            }
            this.videoStreamRenderer = new VideoStreamRenderer(localCameraStream);
            this.viewElement = await this.videoStreamRenderer.createView();
            if (this.previewElement && this.previewElement.nativeElement) {
                this.renderer.setStyle(this.viewElement.target, 'height', '100%');
                this.renderer.setStyle(this.viewElement.target, 'width', '100%');
                this.renderer.appendChild(this.previewElement.nativeElement, this.viewElement.target);
            }
            this.isInitializing = true;
        } else {
            if(this.videoStreamRenderer) {
                this.videoStreamRenderer.dispose();
            }
            this.isInitializing = false;
        }
    }
    
}