import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { Constants } from 'src/app/common/constants';
import { MeetingInfo, UserDetails } from 'src/app/models/Participants';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header-call-screen',
  templateUrl: './header-call-screen.component.html',
  styleUrls: ['./header-call-screen.component.css']
})
export class HeaderCallScreenComponent implements OnInit {
  public Constants = Constants;
  public userDetails: UserDetails;
  public meetingInfo: MeetingInfo;
  public callMinutes: number = 0;
  public callSeconds: number = 0;
  public durationLeft = {
    hours: '00',
    minutes: '00',
    seconds: '00'
  }
  private stopTimer: boolean = false;
  public meetingExtended: boolean = false;
  private timerObservable = timer(1000, 1000);
  private scheduleStartTime;
  private joiningTime;
  @Input() settings = false;
  @Output() openSettings = new EventEmitter();
  @Output() leaveMeeting = new EventEmitter();
  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.joiningTime = this.utcDateTime(new Date());
    this.storageService.getUserDetails().subscribe((userDetails: UserDetails) => {
      this.userDetails = userDetails;
    })
    this.storageService.getMeetingInfo().subscribe((meetingInfo: MeetingInfo) => {
      if(meetingInfo.data) {
        this.meetingInfo = meetingInfo;
        this.durationLeft.hours = String(`0${Math.floor(this.meetingInfo.duration / 60)}`);
        const minutes = this.meetingInfo.duration % 60;
        this.durationLeft.minutes = String((minutes < 10) ? `0${minutes}`: minutes);
        this.scheduleStartTime = new Date(this.meetingInfo.scheduleStartDateTime);
        if (this.utcDateTime(new Date()) > this.scheduleStartTime) {
          const timeDiff = Math.abs(new Date(this.meetingInfo.scheduleStartDateTime).getTime() - this.utcDateTime(new Date()).getTime()) / 1000;
          this.callSeconds = Math.round(timeDiff % 60);
          this.callMinutes = Math.floor(timeDiff / 60);
        }
      }
    })
    const timerObservableRef = this.timerObservable.subscribe(() => {
      if(this.stopTimer) {
        timerObservableRef.unsubscribe;
      } else {
        this.updateMeetingTime();
      }
    })
  }
  private updateMeetingTime(): void {
    if (this.joiningTime < this.scheduleStartTime) {
      this.joiningTime = this.utcDateTime(new Date());
      return;
    }
    this.callSeconds++;
    if (this.callSeconds % 60 === 0) {
      this.callMinutes++;
      this.callSeconds = 0;
    }
    let hoursLeft = Math.floor(((this.meetingInfo.duration - 1) - this.callMinutes) / 60);
    const subtractMinutes = this.callSeconds > 0 ? 1: 0;
    const minutesLeft = ((this.meetingInfo.duration - subtractMinutes) - this.callMinutes) % 60;
    const secondsLeft = this.callSeconds === 0 ? 0 : 60 - this.callSeconds;
    if (minutesLeft < 0 || (minutesLeft === 0 && secondsLeft === 0)) {
      this.durationLeft = {
        hours: '--',
        minutes: '--',
        seconds: '--',
      }
      this.meetingExtended = true;
    } else {
      this.durationLeft = {
        hours: hoursLeft < 10 ? '0' + hoursLeft: String(hoursLeft),
        minutes: minutesLeft >= 0 && minutesLeft < 10 ? '0' + minutesLeft: String(minutesLeft),
        seconds: secondsLeft >= 0 && secondsLeft < 10 ? '0' + secondsLeft: String(secondsLeft),
      }
    }
    if(this.callMinutes >= (this.meetingInfo.duration + 59)) {
      this.stopTimer = true;
      this.leaveMeeting.emit();
    }
  }
  private utcDateTime(currentTime) {
    return new Date(currentTime.getTime() + currentTime.getTimezoneOffset() * 60000);
  }
  toggleSettings() {
    this.openSettings.emit();
  }

}
