import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RecordingResponse, Statistic } from '../models/Api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  getMeetingInfo(meetingId: string) {
    return this.http.get(environment.apiBaseURL + environment.meetingInfoAPI + meetingId);
  }
  saveStatistic(status: Statistic): Observable<any> {
    return this.http.post(environment.apiBaseURL + environment.statisticsAPI, status);
  }
  startRecording( meetingId: string, userType:string,serverCallId: string, userId: string, participantId: string): Observable<any> {
    const url = `${environment.apiBaseURL + environment.startRecordingAPI}${meetingId}/${userType}/${serverCallId}/${userId}/${participantId}`;
    return this.http.get(url);
  }
  recordingStatusUpdate(meetingId: string, userId: string): Observable<any> {
    const url = `${environment.apiBaseURL + environment.recordingStatusUpdateUrl}${meetingId}/${userId}`;
    return this.http.get(url);
  }
  initSession(privateMeetingId: string, serverId: string): Observable<any> {
    const url = `${environment.apiBaseURL + environment.initSessionUrl}${privateMeetingId}/${serverId}`;
    return this.http.get(url);
  }
  saveBrowserInfo(info: any) {
    return this.http.post(`${environment.apiBaseURL.replace('Acs/', '')}save/meeting/user/environment`, info);
  }
  getBrowserInfo(meetingId: string, userId: string): Observable<any> {
    return this.http.get(`${environment.apiBaseURL.replace('Acs/', '')}meeting/user/environment?meetingId=${meetingId}&userId=${userId}`);
  }
}
