import { Injectable } from '@angular/core';
import { Call, CallAgent, CallEndReason, LocalVideoStream, RemoteParticipant } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CommunicationUserToken } from '@azure/communication-identity';
import { Constants } from '../common/constants';
import RemoteStreamSelector from '../common/RemoteStreamSelector';
import { DeviceManagerService } from './device-manager.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';
// import { environment } from '../../environments/environment';
import { NotifictionService } from './notifiction.service';
import { ApiService } from './api.service';
// import { MeetingInfo, UserDetails } from '../models/Participants';
// import { RecordingResponse } from '../models/Api';
import { RecordingUtilsService } from './recording-utils.service';
import { MeetingInfo } from '../models/Participants';

export type TokenResponse = {
  tokenCredential: AzureCommunicationTokenCredential;
  userId: string;
};
interface CallData extends Call {
  info?: any;
};

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  // private userDetails: UserDetails;
  private meetingInfo: MeetingInfo;
  private serverCallId: string;
  private meetingId: string;
  // private id: string;
  // private communcitionUserId: string;
  // private callAgent: CallAgent;
  // private recordingInfo: RecordingResponse
  // private isRecordingStarted: boolean = false;
  // private retryRecordingTimes: number = 0;

  constructor(private utilsService: UtilsService,
    private deviceManagerService: DeviceManagerService,
    private storageService: StorageService,
    private notifictionService: NotifictionService,
    private recordingUtilsService: RecordingUtilsService,
    private apiService: ApiService) {
      this.storageService.getMeetingId().subscribe((meetingId: string) => {
        this.meetingId = meetingId;
      })
    // this.storageService.getUserDetails().subscribe((userDetails: UserDetails) => {
    //   this.userDetails = userDetails;
    // })
    this.storageService.getMeetingInfo().subscribe((meetingInfo: MeetingInfo) => {
      this.meetingInfo = meetingInfo;
    })
  }
  async getToken(meetingId: string): Promise<TokenResponse> {
    const tokenResponse: CommunicationUserToken = await this.utilsService.getTokenForUser(meetingId);
    const userToken = tokenResponse.token;
    const userId = tokenResponse.user.communicationUserId;
    // this.communcitionUserId = tokenResponse.user.communicationUserId;
    // console.log("Login with ACS :" + this.communcitionUserId);

    const tokenCredential = new AzureCommunicationTokenCredential({
      tokenRefresher: (): Promise<string> => {
        return this.utilsService.getRefreshedTokenForUser(userId);
      },
      refreshProactively: true,
      token: userToken
    });

    return {
      tokenCredential,
      userId
    };
  }
  async createCallAgent(
    tokenCredential: AzureCommunicationTokenCredential,
    displayName: string
  ): Promise<CallAgent> {
    const callClient = this.deviceManagerService.getCallClient();

    if (callClient === undefined) {
      throw new Error('CallClient is not initialized');
    }

    const callAgent: CallAgent = await callClient.createCallAgent(tokenCredential, { displayName });
    return callAgent;
  }
  async joinGroup(callAgent: CallAgent, groupId: string, localVideoStream: LocalVideoStream): Promise<void> {
    if (callAgent) {
      try {
        const callOptions = {
          videoOptions: {
            localVideoStreams: localVideoStream ? [localVideoStream] : undefined
          },
          audioOptions: { muted: false }
        }
        await callAgent.join({ groupId }, callOptions);

      } catch (e) {
        console.log('Failed to join a call', e);
        return;
      }
    }
  }
  registerToCallAgent(
    userId: string,
    callAgent: CallAgent,
    callEndedHandler: (reason: CallEndReason) => void
  ): void {
    this.storageService.setCallAgent(callAgent);
    // this.callAgent = callAgent;
    callAgent.on('callsUpdated', (e: { added: CallData[]; removed: Call[] }): void => {
      e.added.forEach((addedCall: CallData) => {
        console.log(`Call added : Call Id = ${addedCall.id}`);
        // this.id = addedCall.id;

        const calls = this.storageService.getCalls();
        if (calls && addedCall.direction === 'Incoming') {
          addedCall.hangUp();
          return;
        }
        this.storageService.setCalls(addedCall);
        this.addCallEvents(addedCall, callAgent);
      })
      e.removed.forEach((removedCall) => {
        const calls = this.storageService.getCalls();
        if (calls && calls === removedCall) {
          this.storageService.setParticipants([]);
          if (removedCall.callEndReason && removedCall.callEndReason.code !== 0) {
            removedCall.callEndReason && callEndedHandler(removedCall.callEndReason);
          }
        }
      });
    })
  };
  private addCallEvents(addedCall: CallData, callAgent: CallAgent): void {
    addedCall.on('stateChanged', (): void => {
      if (addedCall.state === 'Connected') {
        addedCall.info?.getServerCallId().then((result: any) => {
          console.log("ServerId:" + result);
          this.serverCallId = result;
          if(!addedCall.remoteParticipants.length) {
            this.apiService.initSession(this.meetingInfo.privateMeetingId, this.serverCallId).subscribe((response: any) => {
              console.log('initSession response::', response);
            });
          }
        }).catch((err: any) => {
          console.log(err);
        });
      }
      this.storageService.setCallState(addedCall.state);
    })
    this.storageService.setCallState(addedCall.state);
    // addedCall.on('isScreenSharingOnChanged', (): void => {
    // dispatch(setShareScreen(addedCall.isScreenSharingOn));
    // });
    // if remote participants have changed, subscribe to the added remote participants
    addedCall.on('remoteParticipantsUpdated', (ev): void => {
      // for each of the added remote participants, subscribe to events and then just update as well in case the update has already happened
      ev.added.forEach((addedRemoteParticipant: RemoteParticipant) => {
        this.subscribeToParticipant(addedRemoteParticipant, addedCall);
        this.storageService.setParticipants([...this.storageService.getCalls().remoteParticipants, addedRemoteParticipant]);
      });

      // We don't use the actual value we are just going to reset the remoteParticipants based on the call
      if (ev.removed.length > 0) {
        this.storageService.setParticipants([...addedCall.remoteParticipants.values()]);
      }
    });
    this.storageService.setParticipants([...this.storageService.getCalls().remoteParticipants]);
  }
  subscribeToParticipant = (participant: RemoteParticipant, call: Call): void => {
    const dominantParticipantCount = this.utilsService.isSafari()
      ? Constants.DOMINANT_PARTICIPANTS_COUNT_SAFARI
      : Constants.DOMINANT_PARTICIPANTS_COUNT;
    
    const initiatRecording = () => {
      if (participant.displayName && participant.state !== 'Connecting') {
        this.notifictionService.notificationSend({
          userName: participant.displayName,
          state: participant.state
        });
      }
    }
    const remoteStreamSelector = RemoteStreamSelector.getInstance(dominantParticipantCount);
    
    participant.on('stateChanged', async () => {
      if (participant.state === 'Connected') {
            console.log("Participant Connected");
            this.recordingUtilsService.manageRecordingOnStateChange(this.serverCallId);
        }
      initiatRecording();
        
     remoteStreamSelector.participantStateChanged(
        this.utilsService.getId(participant.identifier),
        participant.displayName ?? '',
        participant.state,
        !participant.isMuted,
        participant.videoStreams[0].isAvailable
      );
        
      this.storageService.setParticipants([...call.remoteParticipants.values()]);
    });
    participant.on('displayNameChanged', () => {
      initiatRecording();
    })

    participant.on('isMutedChanged', () => {
      remoteStreamSelector.participantAudioChanged(this.utilsService.getId(participant.identifier), !participant.isMuted);
    });

    participant.on('isSpeakingChanged', () => {
      this.storageService.setParticipants([...call.remoteParticipants.values()]);
    });

    participant.on('videoStreamsUpdated', (e): void => {
      e.added.forEach((addedStream) => {
        if (addedStream.mediaStreamType === 'ScreenSharing') {
          addedStream.on('isAvailableChanged', () => {
            if (addedStream.isAvailable) {
              this.storageService.addScreenShareStream(addedStream, participant);
            } else {
              this.storageService.removeScreenShareStream(addedStream, participant);
            }
          });

          if (addedStream.isAvailable) {
            this.storageService.addScreenShareStream(addedStream, participant);
          }
        } else if (addedStream.mediaStreamType === 'Video') {
          addedStream.on('isAvailableChanged', () => {
            remoteStreamSelector.participantVideoChanged(this.utilsService.getId(participant.identifier), addedStream.isAvailable);
          });
        }
      });
      this.storageService.setParticipants([...call.remoteParticipants.values()]);
    });
  };
  // manageRecordingOnStateChange(participant, call) {

  //   console.log("manageRecordingOnStateChange");

  //   if (participant.state === 'Connected') {
  //     console.log("Participant Connected");
  //     this.startRecording();
  //   }
  //   else if (participant.state === 'Disconnected') {
  //     if (!this.recordingInfo) return;

  //     if (this.recordingInfo.paticipantId === this.utilsService.getId(participant.identifier)) {
  //       console.log(this.id, " >> Closing Recording : ", this.recordingInfo.paticipantId, ">>", this.utilsService.getId(participant.identifier));
  //       this.apiService.recordingStatusUpdate(this.meetingInfo.privateMeetingId, this.utilsService.getId(participant.identifier))
  //         .subscribe((response) => {
  //           this.recordingInfo.paticipantId = "";
  //           this.isRecordingStarted = false;
  //           if (call.remoteParticipants.length > 0) {
  //             this.isRecordingStarted = false;
  //             this.startRecording();
  //           }
  //         });
  //     }
  //   }

  // }
  // startRecording() {
  //   if (this.isRecordingStarted) return;

  //   if (!this.recordingInfo || this.recordingInfo.paticipantId === "") {
  //     this.isRecordingStarted = true;
  //     if (this.userDetails) {
  //       console.log("Sending Recording Request for Id:", this.communcitionUserId);
  //       this.apiService.startRecording(this.meetingInfo.privateMeetingId, this.userDetails.userType, this.serverCallId, this.id, this.communcitionUserId)
  //         .subscribe((response: RecordingResponse) => {
  //           if (!response || !response.recordingId || response.recordingId === "INVALID") {
  //             console.log("Recording Failed to Start");
  //             this.isRecordingStarted = false;
  //             /** Retry */
  //             this.retryRecording();

  //             return;
  //           }
  //           this.retryRecordingTimes = 0;
  //           this.isRecordingStarted = false;
  //           this.recordingInfo = response;
  //           this.storageService.setRecordingInfo(response);
  //           console.log("Recording Started for:" + response.paticipantId);
  //         })
  //     }
  //   }

  // }

  // /** Retry Recording  */
  // retryRecording() {
  //   this.retryRecordingTimes++;
  //   let randomNo = Math.floor(Math.random() * 10000) + 30000;
  //   if (this.retryRecordingTimes > 10) {
  //     this.retryRecordingTimes = 0;
  //     console.log("Max Recording retry reached" + this.retryRecordingTimes);
  //     return;
  //   } else if (this.retryRecordingTimes > 4) {
  //     randomNo = Math.floor(Math.random() * 10000) + 60000;
  //   }

  //   randomNo = Math.floor(Math.random() * 10000) + 60000;
  //   setTimeout(() => {
  //     console.log("Retrying Recording::" + this.retryRecordingTimes + " Count (wait: " + randomNo + ")");
  //     this.startRecording();
  //   }, randomNo);
  //   return;
  // }

}
