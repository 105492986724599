import { Component, OnInit } from '@angular/core';
import { AudioDeviceInfo, Call, CallAgent, Features, LocalVideoStream, RemoteParticipant, VideoDeviceInfo } from '@azure/communication-calling';
import { StorageService } from '../../services/storage.service';
import { SelectionState } from 'src/app/common/RemoteStreamSelector';
import { DeviceManagerService } from 'src/app/services/device-manager.service';
import { environment } from 'src/environments/environment';
import { NotifictionService } from 'src/app/services/notifiction.service';
import { JoinMeetingNotification, MeetingInfo, UserDetails } from 'src/app/models/Participants';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/common/constants';
import { timer } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';

interface BrowserInfo {
  meetingId: string;
  userId: string;
  userAgent: string;
}


@Component({
  selector: 'app-call-screen',
  templateUrl: './call-screen.component.html',
  styleUrls: ['./call-screen.component.css']
})
export class CallScreenComponent implements OnInit {
  public Constants = Constants;
  public selectedVideoDevice: VideoDeviceInfo;
  public selectedAudioDevice: AudioDeviceInfo;
  public callState: string = 'None';
  public localParticipantMuted: boolean = false;
  public localParticipantCameraOff: boolean = false;
  public storeLocalStream: LocalVideoStream;
  public participantsStreams: RemoteParticipant[] = [];
  public usersPanel: boolean = false;
  public settings: boolean = false;
  public dominantParticipants: SelectionState[] = [];
  public cameraDevices: VideoDeviceInfo[] = [];
  public microphoneDevices: AudioDeviceInfo[] = [];
  public displayChatScreen: boolean = false;
  public participantsMinimize: boolean = false;
  public fullScreenParticipant: RemoteParticipant | null = null;
  public enableRecording: boolean = environment.recording;
  public openNotification: boolean = false;
  public joinNotificationMessage: JoinMeetingNotification;
  public userDetails: UserDetails;
  public meetingInfo: MeetingInfo;
  private call: Call;
  public leavingMeeting: boolean = false;
  public openConfirmModal: boolean = false;
  public openRemoveParticipantConfirmModal: boolean = false;
  private notificationSubscriberRef;
  public durationLeft = {
    hours: '00',
    minutes: '00',
    seconds: '00'
  }
  public callMinutes: number = 0;
  public callSeconds: number = 0;
  private stopTimer: boolean = false;
  private timerObservable = timer(1000, 1000);
  private participantForRemove = null;
  public info: boolean = false;
  public participantToDisplayBrowserInfo: BrowserInfo;
  constructor(private storageService: StorageService,
    private deviceManagerService: DeviceManagerService,
    private notifictionService: NotifictionService,
    private utilsService: UtilsService,
    private apiService: ApiService,
    private router: Router) { }

  ngOnInit(): void {
    this.storageService.getUserDetails().subscribe((userDetails: UserDetails) => {
      this.userDetails = userDetails;
    });

    this.storageService.getParticipants().subscribe((participantsList: RemoteParticipant[]) => {
      this.call = this.storageService.getCalls();
      this.participantsStreams = [...this.storageService.getCalls().remoteParticipants];
    });
    this.storageService.getCallState().subscribe((value: string) => {
      this.callState = value;
        if (value !== 'Connecting' && value !== 'Disconnecting') {
         this.apiService.saveStatistic({
              meetingId: this.meetingInfo.id,
              status: value,
              type: this.userDetails.userType === Constants.TYPE_CAREMEMBER ? 'Care Member' : 'Patient',
             description: `${this.userDetails.userName} ${value}`
         }).subscribe((params) => {
         });          
        if(value === 'Disconnected' && !this.leavingMeeting) {
          this.leaveMeeting();
        }
      }
    });
    this.storageService.getLocalStream().subscribe((localStream: LocalVideoStream) => {
      this.storeLocalStream = localStream;
    });
    

    this.storageService.getDominantParticipants().subscribe((participants: SelectionState[]) => {
      this.dominantParticipants = participants;
    })
    this.storageService.getMeetingInfo().subscribe((meetingInfo: MeetingInfo) => {
      if(meetingInfo.data) {
        this.meetingInfo = meetingInfo;
        this.deviceSettings();
      } 
      else {
        const meetingId = localStorage.getItem('renalIQVideoUI_meetingId');
        const userDetails = localStorage.getItem('renalIQVideoUI_userDetails');
        const userType = userDetails ? JSON.parse(userDetails).userType : '';
        if (meetingId && userType) {
          window.open(`${location.origin}/#/join/${userType}/${meetingId}`, '_self');
        } else {
          this.router.navigate(['error']);
        }
      }
    })
    this.notificationSubscriberRef = this.notifictionService.notificationSubscriber().subscribe((message: JoinMeetingNotification) => {
      if (message.userName) {
        this.joinNotificationMessage = message;
        this.openNotification = true;
        setTimeout(() => {
          this.openNotification = false;
        }, 5000);
      }

    })
    this.storageService.getCallAgent().subscribe((callAgent: CallAgent) => {
    })
    const timerObservableRef = this.timerObservable.subscribe(() => {
      if(this.stopTimer) {
        timerObservableRef.unsubscribe;
      } else {
        this.updateMeetingTime();
      }
    })
    this.saveDetails();
  }
  private async saveDetails() {
    try {
      await this.apiService.saveBrowserInfo({
        meetingId: this.meetingInfo.id,
        userId: this.storageService.getCommunicationUserId(),
        userAgent: navigator.userAgent
      }).toPromise();
    } catch (error) {}
    if ( this.userDetails.userType === Constants.TYPE_CAREMEMBER ) {
      this.logsBrowserInfo();
    }
  }
  private async getBrowserDetails(userId: string) {
    try {
      this.apiService.getBrowserInfo(this.meetingInfo.id, userId).subscribe((response: BrowserInfo) => {
        this.participantToDisplayBrowserInfo = response;
      });
    } catch (error) {}
    
  }
  private async deviceSettings(): Promise<void> {
    this.cameraDevices = await this.deviceManagerService.getCameras();
    this.microphoneDevices = await this.deviceManagerService.getMicrophones();
    this.selectedVideoDevice = this.storageService.getVideoDeviceInfo();
    this.selectedAudioDevice = this.storageService.getAudioDeviceInfo();
  }
  muteEventHandlar(): void {
    if (this.localParticipantMuted) {
      this.localParticipantMuted = false;
    } else {
      this.localParticipantMuted = true;
    }
  }
  async cameraOnOffEventHandlar() {
    if (this.storeLocalStream && this.storageService.getCalls()) {
      this.storageService.getCalls().stopVideo(this.storeLocalStream);
      this.storageService.setLocalStream(undefined);
      this.localParticipantCameraOff = true;
    } else {
      if (this.storageService.getVideoDeviceInfo() && this.storageService.getCalls()) {
        const localVideoStream = new LocalVideoStream(this.storageService.getVideoDeviceInfo());
        this.storageService.setLocalStream(localVideoStream);
        this.storageService.getCalls().startVideo(localVideoStream);
        this.localParticipantCameraOff = false;
      }
    }
  }
  async handleMuteOnOff() {
    if (this.localParticipantMuted) {
      this.storageService.getCalls().unmute();
      this.localParticipantMuted = false;
    } else {
      this.storageService.getCalls().mute();
      this.localParticipantMuted = true;
    }
  }
  onSettingsChanged($event: any) {
    let type = 'CAMERA';
    if ($event.label === 'CAMERA') {
      this.storageService.setVideoDeviceInfo($event.deviceInfo);
      if (this.call.localVideoStreams) {
        this.call.localVideoStreams[0].switchSource($event.deviceInfo);
      }
    } else if ($event.label === 'MICROPHONE') {
      this.storageService.setAudioDeviceInfo($event.deviceInfo);
      type = 'MICROPHONE';
      this.deviceManagerService.deviceManager.selectMicrophone($event.deviceInfo);
    }
    this.apiService.saveStatistic({
      meetingId: this.call.id,
      status: 'DeviceInfo',
      type: type,
      description: `${$event.deviceInfo}`
    })
  }
  getParticipantInfo(participant: RemoteParticipant) {
    return this.dominantParticipants.filter((item: SelectionState) => {
      return item.participantId === participant.displayName
    })
  }
  private updateMeetingTime(): void {
    this.callSeconds++;
    if (this.callSeconds % 60 === 0) {
      this.callMinutes++;
      this.callSeconds = 0;
    }
    let hoursLeft = Math.floor(((this.meetingInfo.duration - 1) - this.callMinutes) / 60);
    const subtractMinutes = this.callSeconds > 0 ? 1: 0;
    const minutesLeft = ((this.meetingInfo.duration - subtractMinutes) - this.callMinutes) % 60;
    const secondsLeft = this.callSeconds === 0 ? 0 : 60 - this.callSeconds;
    hoursLeft = hoursLeft < 0 ? 0: hoursLeft;
    this.durationLeft = {
      hours: hoursLeft < 10 ? '0' + hoursLeft: String(hoursLeft),
      minutes: minutesLeft >= 0 && minutesLeft < 10 ? '0' + minutesLeft: String(minutesLeft),
      seconds: secondsLeft >= 0 && secondsLeft < 10 ? '0' + secondsLeft: String(secondsLeft),
    }
    if(this.callMinutes >= (this.meetingInfo.duration + 59)) {
      this.stopTimer = true;
      this.leaveMeeting();
    }
  }
  leaveMeetingHandler(): void {
    this.openConfirmModal = true;
  }
  leaveMeeting(): void {
    this.notificationSubscriberRef.unsubscribe;
    this.openConfirmModal = false;
    this.leavingMeeting = true;
    this.call.hangUp({ forEveryone: false });
    setTimeout(() => {
      const { userName, userType } = this.userDetails;
        if (userType === Constants.TYPE_PATIENT) {
            window.open(environment.patientRedirectUrl, '_self');
        } else {
            window.open(`${environment.baseURL}${environment.feedback}?meetingId=${this.meetingInfo.privateMeetingId}`, '_self');
        }
    }, 2000);
  }
  selectParticipantHandler(participant: RemoteParticipant): void {
    if (this.participantsStreams.length > 1) {
      this.fullScreenParticipant = participant;
      this.participantsMinimize = this.participantsStreams.length > 1 ? true: false;
    }
    
  }
  removeParticipant($event) {
    this.participantForRemove = $event;
    this.openRemoveParticipantConfirmModal = true;
  }
  removeParticipantConfirmHandler() {
    for (let i = 0; i < this.call.remoteParticipants.length; i++ ) {
      if (this.call.remoteParticipants[i].videoStreams[0].id === this.participantForRemove.videoStreams[0].id) {
        this.call.removeParticipant(this.call.remoteParticipants[i].identifier);
        if (this.fullScreenParticipant && this.fullScreenParticipant.videoStreams[0].id === this.participantForRemove.videoStreams[0].id) this.fullScreenParticipant = null;
        break;
      }
    }
    this.openRemoveParticipantConfirmModal = false;
  }
  logsBrowserInfo() {
    this.participantsStreams.forEach((element: RemoteParticipant) => {
      this.apiService.saveStatistic({
        meetingId: this.meetingInfo.id,
        status: element.state,
        type: element.displayName ? element.displayName : 'Participant',
        description: `${element.displayName ? element.displayName : 'Participant'} ${this.utilsService.browser(navigator.userAgent)}`
      }).subscribe((params) => {
          console.log('Saved Browser info:', params);
      });
    });
  }
  openSettings() {
    this.settings = !this.settings;
    this.displayChatScreen = false;
  }
  openInfo($event?: RemoteParticipant) {
    if ($event) {
      this.getBrowserDetails($event['_tsParticipant'].id);
    }
    this.info = !this.info;
    this.settings = false;
    this.displayChatScreen = false;
  }
}
