import { Injectable } from '@angular/core';
import { AudioDeviceInfo, RemoteVideoStream, VideoDeviceInfo } from '@azure/communication-calling';
import { CommunicationIdentifierKind } from '@azure/communication-common';
import { CommunicationUserToken } from '@azure/communication-identity';
import { environment } from '../../environments/environment';
import { v1 as createGUID } from 'uuid';
// import preval from 'preval.macro';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  getAppServiceUrl(): string {
    return window.location.origin;
  }
  async getTokenForUser(meetingId: string): Promise<CommunicationUserToken> {
    const response = await fetch(environment.apiBaseURL + environment.tokenAPI + meetingId);
    if (response.ok) {
      return response.json();
    }
    throw new Error('Invalid token response');
  }
  async getRefreshedTokenForUser(identity: string): Promise<string> {
    const response = await fetch(environment.apiBaseURL + environment.refreshTokenAPI + `${identity}`);
    if (response.ok) {
      const content = await response.json();
      return content.token;
    }
    throw new Error('Invalid token response');
  }
  isSelectedAudioDeviceInList(selected: AudioDeviceInfo, list: AudioDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  }
  isSelectedVideoDeviceInList(selected: VideoDeviceInfo, list: VideoDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  }
  isMobileSession(): boolean {
    return window.navigator.userAgent.match(/(iPad|iPhone|iPod|Android|webOS|BlackBerry|Windows Phone)/g)
      ? true
      : false;
  }
  isSmallScreen(): boolean {
    return window.innerWidth < 700 || window.innerHeight < 400;
  }
  isUnsupportedBrowser(): boolean {
    return window.navigator.userAgent.match(/(Firefox)/g) ? true : false;
  }
  getId(identifier: CommunicationIdentifierKind): string {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
    }
  }
  getStreamId(userId: string, stream: RemoteVideoStream): string {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  }
  /*
   * TODO:
   *  Remove this method once the SDK improves error handling for unsupported browser.
   */
  isOnIphoneAndNotSafari(): boolean {
    const userAgent = navigator.userAgent;
    // Chrome uses 'CriOS' in user agent string and Firefox uses 'FxiOS' in user agent string.
    if (userAgent.includes('iPhone') && (userAgent.includes('FxiOS') || userAgent.includes('CriOS'))) {
      return true;
    }
    return false;
  }
  isSafari(): boolean {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return isSafari;
  }

  getMeetingIdFromUrl(): string  {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('groupId');
  };
  
  public getMeetingId(): string{
    
      const uriGid = this.getMeetingIdFromUrl();
      const gid = uriGid == null || uriGid === '' ? createGUID() : uriGid;
      console.log("Meeting id" + gid);
      return gid;
  };
  browser = (str: any) => {
    const test = function(regexp) {return regexp.test(str)}
    switch (true) {
      case test(/edg/i): return `<b>Browser:</b> Microsoft Edge v${str.split('Edg/')[1].split(' ')[0]}<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      case test(/trident/i): return `<b>Browser:</b> Microsoft Internet Explorer v${str.split('Trident/')[1].split(' ')[0]}<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      case test(/firefox|fxios/i): return `<b>Browser:</b> Mozilla Firefox v${str.split('Firefox/')[1].split(' ')[0]}<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      case test(/opr\//i): return `<b>Browser:</b> Opera v${str.split('Opera/')[1].split(' ')[0]}<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      case test(/ucbrowser/i): return `<b>Browser:</b> UC Browser<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      case test(/samsungbrowser/i): return `<b>Browser:</b> Samsung Browser<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> User Agent Details:</b> ${str}`;
      case test(/chrome|chromium|crios/i): return `<b>Browser:</b> Google Chrome v${str.split('Chrome/')[1].split(' ')[0]}<br/><b>Operating System:</b> ${this.operatingSystem(str)}<br/><b>User Agent Details:</b> ${str}`;
      case test(/safari/i): return `<b>Browser:</b> Apple Safari v${str.split('Safari/')[1].split(' ')[0]}<br/> <b>Operating System:</b> ${this.operatingSystem(str)}<br/> <b>User Agent Details:</b> ${str}`;
      default: return "Other";
    }
    
  };
  operatingSystem(userAgent: any) {
    if (userAgent.indexOf("Win") != -1) return "Windows OS";
    if (userAgent.indexOf("Mac") != -1) return  "Macintosh";
    if (userAgent.indexOf("Linux") != -1) return  "Linux OS";
    if (userAgent.indexOf("Android") != -1) return  "Android OS";
    if (userAgent.indexOf("like Mac") != -1) return  "iOS";
  }

}
