import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ActivityIndicatorComponent } from './activity-indicator/activity-indicator.component';
import { AppRoutingModule } from './app-routing.module';
import { VideoCallModule } from './video-call/video-call.module';
import { PatientConsentComponent } from './patient-consent/patient-consent.component';
import { CommonDateTimeFormatter } from './common/pipes/common-date-time-formatter';
import { CommonsModule } from './common/commons.module';
import { DatePipe } from '@angular/common';
import { ErrorComponent } from './error/error/error.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { BASE_PATH } from './services/variables';
import { environment } from '../environments/environment';
import { RequestHeadersInterceptor } from './services/request-headers-interceptor';



const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);   // For log on console 
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.authClientID,
            authority: environment.authAuthority,
            redirectUri: environment.authRedirectURI,
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.graph_endpoint, ['user.read']);
    protectedResourceMap.set(environment.baseURL, [environment.apiClientID]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        },
        loginFailedRoute: '/login-failed'
    };
}

@NgModule({
    declarations: [
        AppComponent,
        ActivityIndicatorComponent,
        PatientConsentComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        VideoCallModule,
        CommonsModule,
    ],
    providers: [
        HttpClientModule,
        CommonDateTimeFormatter,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestHeadersInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: BASE_PATH, useValue: environment.baseURL },
        { provide: CommonDateTimeFormatter, useFactory: (dataTimePipe: DatePipe) => new CommonDateTimeFormatter(dataTimePipe), deps: [DatePipe] }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
