import { Injectable } from '@angular/core';
import { RecordingResponse } from '../models/Api';
import { MeetingInfo, UserDetails } from '../models/Participants';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class RecordingUtilsService {
  private userDetails: UserDetails;
  private isRecordingStarted: boolean = false;
  private retryRecordingTimes: number = 0;
  private recordingInfo: RecordingResponse;
  private meetingInfo: MeetingInfo;
  private serverCallId: string;
  constructor(private utilsService: UtilsService,
      private storageService: StorageService,
      private apiService: ApiService) {
    this.storageService.getUserDetails().subscribe((userDetails: UserDetails) => {
      this.userDetails = userDetails;
    });
    this.storageService.getMeetingInfo().subscribe((meetingInfo: MeetingInfo) => {
      this.meetingInfo = meetingInfo;
    })
  }
  manageRecordingOnStateChange(serverCallId: string) {
    this.serverCallId = serverCallId;
    console.log("manageRecordingOnStateChange");
    this.startRecording();
    // if (participant.state === 'Connected') {
    //   console.log("Participant Connected");
    //   this.startRecording();
    // }
    // else if (participant.state === 'Disconnected'){
    //   if(!this.recordingInfo) return;
      
    //   if(this.recordingInfo.paticipantId === this.utilsService.getId(participant.identifier)) {
    //   console.log(this.id, " >> Closing Recording : ", this.recordingInfo.paticipantId, ">>", this.utilsService.getId(participant.identifier));
    //   this.apiService.recordingStatusUpdate(this.meetingInfo.privateMeetingId, this.utilsService.getId(participant.identifier))
    //     .subscribe((response) => {
    //       this.recordingInfo.paticipantId = "";
    //       this.isRecordingStarted = false;
    //       if (call.remoteParticipants.length > 0) {
    //         this.isRecordingStarted = false;
    //         this.startRecording();
    //       }
    //     });
    // }
  }
  startRecording() {
    if (this.isRecordingStarted) return;

    if(!this.recordingInfo || this.recordingInfo.paticipantId === "") {
        this.isRecordingStarted = true;
        if (this.userDetails) {
          const communcitionUserId = this.storageService.getCommunicationUserId();
          const { id } = this.storageService.getCalls();
          console.log("Sending Recording Request for Id:", communcitionUserId);
          console.log("Id:", id);
          console.log("this.serverCallId:", this.serverCallId);
          this.apiService.startRecording( this.meetingInfo.privateMeetingId,this.userDetails.userType, this.serverCallId, id, communcitionUserId)
            .subscribe((response: RecordingResponse) => {
              if (!response || !response.recordingId || response.recordingId === "INVALID") {
                console.log("Recording Failed to Start");
                this.isRecordingStarted=false;
                /** Retry */
                this.retryRecording();

                return;
              }
              this.retryRecordingTimes = 0;
              this.isRecordingStarted = false;
              this.recordingInfo = response;
              this.storageService.setRecordingInfo(response);
              console.log("Recording Started for:" + response.paticipantId);
            })
        }
    }
  
  }
  /** Retry Recording  */
  retryRecording(){
    this.retryRecordingTimes++;
    let randomNo = Math.floor(Math.random()* 10000)  + 30000;
    if (this.retryRecordingTimes > 10) {
      this.retryRecordingTimes=0;
      console.log("Max Recording retry reached" + this.retryRecordingTimes);
      return;
    }else if (this.retryRecordingTimes > 4) {
      randomNo = Math.floor(Math.random()* 10000)  + 60000;
    }

    randomNo = Math.floor(Math.random()* 10000)  + 60000;
    setTimeout(() => {
      console.log("Retrying Recording::" + this.retryRecordingTimes+ " Count (wait: "+randomNo+")");
      this.startRecording();
    }, randomNo);
    return;
  }
}
