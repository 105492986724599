import { Injectable } from '@angular/core';
import { AudioDeviceInfo, CallClient, DeviceManager, VideoDeviceInfo } from '@azure/communication-calling';

@Injectable({
  providedIn: 'root'
})
export class DeviceManagerService {
  public deviceManager: DeviceManager;
  private _callClient: CallClient;
  constructor() { }
  setCallClient() {
    this._callClient = new CallClient({});
  }
  getCallClient() {
    if(!this._callClient) {
      this.setCallClient();
    }
    return this._callClient;
  }
  async getDeviceManager(): Promise<DeviceManager> {
    const callClient = this.getCallClient();
    this.deviceManager = await callClient.getDeviceManager();
    return this.deviceManager;
  }
  async getCameras(): Promise<VideoDeviceInfo[]> {
    if(!this.deviceManager) {
      await this.getDeviceManager();
    }
    return this.deviceManager.getCameras();
  }
  async getMicrophones(): Promise<AudioDeviceInfo[]> {
    if(!this.deviceManager) {
      await this.getDeviceManager();
    }
    return this.deviceManager.getMicrophones();
  }
  async getSpeakers(): Promise<AudioDeviceInfo[]> {
    if(!this.deviceManager) {
      await this.getDeviceManager();
    }
    return this.deviceManager.getSpeakers();
  }
}
