<section class="section">
    <header class="section__header">
        <div class="d-flex">
            <div>
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus" />
            </div>
        </div>
    </header>
    <div class="section__content">
        <div class="container">
            <div class="text-center">
                <div class="mt-2">
                    <span class="text-primary">Error on connecting.</span>
                    <div class="fs-14 opacity-05">Please restart meeting.</div>
                </div>
            </div>
        </div>
    </div>
</section>