<section
    class="section"
    [ngClass]="{ 'is-active': displayChatScreen || settings }"
>
    <main class="section__main">
        <!-- Header start -->
        <app-header-call-screen
            [settings]="settings"
            (openSettings)="openSettings()"
            (leaveMeeting)="leaveMeeting()"
        ></app-header-call-screen>
        <!-- Header end -->
        <div class="section__content" *ngIf="!leavingMeeting">
            <!-- Meeting joined, left notification start -->
            <div class="toasts__wrap" role="alert">
                <div
                    *ngIf="openNotification"
                    class="toasts"
                    aria-live="assertive"
                    aria-atomic="true"
                >
                    <div
                        class="toasts__close"
                        (click)="openNotification = !openNotification"
                    >
                        <svg viewBox="3 3 18 18" class="svg svg-05">
                            <use
                                class="svg-icon"
                                xlink:href="#svg_cancelfill"
                            ></use>
                        </svg>
                    </div>
                    <div class="toasts__icon">
                        <span>{{
                            joinNotificationMessage.userName
                                .slice(0, 2)
                                .toUpperCase()
                        }}</span>
                    </div>
                    <div>
                        <div class="toasts__user">
                            {{ joinNotificationMessage.userName }}
                        </div>
                        {{
                            joinNotificationMessage.state === "Connected"
                                ? "joined"
                                : "left"
                        }}
                        Meeting.
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <!-- Meeting joined, left notification end -->
            <div id="video-call-section" class="section__content-video">
                <div
                    class="participants__icon"
                    [ngClass]="{ 'is-active': participantsMinimize }"
                    *ngIf="
                        fullScreenParticipant && participantsStreams.length > 1
                    "
                    (click)="
                        participantsMinimize = !participantsMinimize;
                        settings = false
                    "
                >
                    <svg
                        viewBox="0 0 24 24"
                        class="svg svg-1-25 svg-white participant__icon"
                        aria-hidden="true"
                    >
                        <use
                            class="svg-icon"
                            xlink:href="#svg_arrow-right"
                        ></use>
                    </svg>
                </div>
                <div
                    class="section__content-media"
                    (click)="participantsMinimize = false"
                >
                    <div
                        class="user-icon"
                        *ngIf="participantsStreams.length === 0"
                    >
                        <svg
                            viewBox="0 0 489 489"
                            class="svg svg-secondary opacity-05"
                            aria-hidden="true"
                        >
                            <use class="svg-icon" xlink:href="#svg_user"></use>
                        </svg>
                        <div class="user-icon__text">
                            Waiting for
                            {{
                                userDetails.userType ===
                                Constants.TYPE_CAREMEMBER
                                    ? "Patient"
                                    : "Somatus Team"
                            }}
                            to join the call.
                        </div>
                    </div>
                    <div
                        class="section__content-media section__content-01"
                        *ngIf="fullScreenParticipant"
                    >
                        <camera
                            [localVideoStream]="
                                fullScreenParticipant.videoStreams[0]
                            "
                            *ngIf="
                                fullScreenParticipant.videoStreams[0]
                                    .isAvailable
                            "
                        ></camera>
                        <div class="user-icon user-icon--sm">
                            <svg
                                viewBox="0 0 489 489"
                                aria-hidden="true"
                                class="svg svg-secondary opacity-05"
                            >
                                <use
                                    xlink:href="#svg_user"
                                    class="svg-icon"
                                ></use>
                            </svg>
                        </div>
                        <app-participant-info
                            [participantInfo]="fullScreenParticipant"
                            [enableRemoveParticipant]="
                                userDetails.userType !== Constants.TYPE_PATIENT
                            "
                            (removeParticipant)="removeParticipant($event)"
                            (openInfo)="openInfo($event)"
                        ></app-participant-info>
                    </div>
                    <ng-container *ngIf="!fullScreenParticipant">
                        <div
                            class="section__content-media"
                            [ngClass]="{
                                'section__content-01':
                                    participantsStreams.length === 0,
                                'section__content-02':
                                    participantsStreams.length === 1,
                                'section__content-03':
                                    participantsStreams.length === 2,
                                'section__content-04':
                                    participantsStreams.length > 2
                            }"
                        >
                            <camera
                                #camera
                                [localVideoStream]="storeLocalStream"
                                *ngIf="!localParticipantCameraOff"
                            >
                            </camera>
                            <app-participant-info
                                [participantInfo]="{
                                    displayName: userDetails.userName + ' (me)',
                                    isMuted: localParticipantMuted
                                }"
                                [isVideoOn]="!localParticipantCameraOff"
                                [enableRemoveParticipant]="false"
                            >
                            </app-participant-info>
                        </div>
                        <div
                            class="section__content-media"
                            [ngClass]="{
                                'section__content-01':
                                    participantsStreams.length === 0,
                                'section__content-02':
                                    participantsStreams.length === 1,
                                'section__content-03':
                                    participantsStreams.length === 2,
                                'section__content-04':
                                    participantsStreams.length > 2
                            }"
                            (click)="selectParticipantHandler(participant)"
                            *ngFor="let participant of participantsStreams"
                        >
                            <camera
                                [localVideoStream]="participant.videoStreams[0]"
                                *ngIf="participant.videoStreams[0].isAvailable"
                            ></camera>
                            <div class="user-icon user-icon--sm">
                                <svg
                                    viewBox="0 0 489 489"
                                    aria-hidden="true"
                                    class="svg svg-secondary opacity-05"
                                >
                                    <use
                                        xlink:href="#svg_user"
                                        class="svg-icon"
                                    ></use>
                                </svg>
                            </div>
                            <app-participant-info
                                [participantInfo]="participant"
                                [enableRemoveParticipant]="
                                    userDetails.userType !==
                                    Constants.TYPE_PATIENT
                                "
                                (removeParticipant)="removeParticipant($event)"
                                (openInfo)="openInfo($event)"
                            ></app-participant-info>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- <div class="my-video">
                <div class="user-icon user-icon--sm">
                    <svg viewBox="0 0 489 489" class="svg svg-secondary opacity-05" aria-hidden="true">
                        <use class="svg-icon" xlink:href="#svg_user"></use>
                    </svg>
                </div>
                <camera #camera [localVideoStream]="storeLocalStream"></camera>
            </div> -->

            <div class="section__content-footer">
                <ul
                    class="video-options"
                    [ngClass]="{ 'is-grid': fullScreenParticipant }"
                >
                    <li class="video-options__item video-options--time">
                        <span class="video-options__time">
                            {{
                                callMinutes < 10
                                    ? "0" + callMinutes
                                    : callMinutes
                            }}:
                            {{
                                callSeconds < 10
                                    ? "0" + callSeconds
                                    : callSeconds
                            }}
                        </span>
                    </li>
                    <li class="video-options__item">
                        <a
                            href="javascript:void(0)"
                            class="video-options__link"
                            [ngClass]="{ 'is-disable': localParticipantMuted }"
                            (click)="handleMuteOnOff()"
                        >
                            <svg
                                viewBox="0 0 42.67 64"
                                class="svg svg-1-5 svg-white align-text-top"
                                aria-hidden="true"
                            >
                                <use
                                    class="svg-icon"
                                    xlink:href="#svg_mike"
                                ></use>
                            </svg>
                        </a>
                    </li>
                    <li class="video-options__item">
                        <a
                            href="javascript:void(0)"
                            class="video-options__link"
                            [ngClass]="{
                                'is-disable': localParticipantCameraOff
                            }"
                            (click)="cameraOnOffEventHandlar()"
                        >
                            <svg
                                viewBox="0 0 32 32"
                                class="svg svg-1-5 svg-white align-text-top"
                                aria-hidden="true"
                            >
                                <use
                                    class="svg-icon"
                                    xlink:href="#svg_camera"
                                ></use>
                            </svg>
                        </a>
                    </li>
                    <li
                        class="video-options__item"
                        *ngIf="fullScreenParticipant"
                    >
                        <a
                            href="javascript:void(0)"
                            class="video-options__link"
                            [ngClass]="{
                                'is-disable': localParticipantCameraOff
                            }"
                            (click)="fullScreenParticipant = null"
                        >
                            <svg
                                viewBox="0 0 16 16"
                                class="svg svg-1-5 svg-white align-text-top"
                                aria-hidden="true"
                            >
                                <use
                                    class="svg-icon"
                                    xlink:href="#svg_grid"
                                ></use>
                            </svg>
                        </a>
                    </li>
                    <li class="video-options__item">
                        <a
                            href="javascript:void(0)"
                            class="video-options__link is-danger"
                            (click)="leaveMeetingHandler()"
                        >
                            <svg
                                viewBox="0 0 568.766 568.766"
                                class="svg svg-1-5 svg-white align-text-top"
                                aria-hidden="true"
                            >
                                <use
                                    class="svg-icon"
                                    xlink:href="#svg_callend"
                                ></use>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="text-center" *ngIf="leavingMeeting">
            <div class="mt-2">
                <span class="text-primary">Please wait...</span>
            </div>
        </div>
    </main>
    <!-- <app-chat *ngIf="displayChatScreen"></app-chat> -->
    <div class="aside" *ngIf="settings">
        <div class="aside__header">
            Device Settings
            <div
                class="aside__close"
                (click)="settings = !settings; displayChatScreen = false"
            >
                <svg
                    viewBox="0 0 24 24"
                    class="svg svg-secondary svg-1"
                    aria-hidden="true"
                >
                    <use class="svg-icon" xlink:href="#svg_close"></use>
                </svg>
            </div>
        </div>
        <div class="aside__container">
            <form class="form">
                <div class="form-group" *ngIf="cameraDevices.length > 0">
                    <app-device-setting
                        [label]="'CAMERA'"
                        [devices]="cameraDevices"
                        [selectedId]="selectedVideoDevice._id"
                        (settingsChanged)="onSettingsChanged($event)"
                    >
                    </app-device-setting>
                </div>
                <hr class="opacity-05" />
                <div class="form-group" *ngIf="microphoneDevices.length > 0">
                    <app-device-setting
                        [label]="'MICROPHONE'"
                        [devices]="microphoneDevices"
                        [selectedId]="selectedAudioDevice._id"
                        (settingsChanged)="onSettingsChanged($event)"
                    >
                    </app-device-setting>
                </div>
            </form>
        </div>
    </div>
    <div
        class="participants"
        [style.visibility]="participantsMinimize ? 'visible' : 'hidden'"
    >
        <ng-container *ngIf="fullScreenParticipant">
            <div class="participants__block">
                <camera
                    #camera
                    [localVideoStream]="storeLocalStream"
                    *ngIf="!localParticipantCameraOff"
                ></camera>
                <app-participant-info
                    [participantInfo]="{
                        displayName: userDetails.userName + ' (me)',
                        isMuted: localParticipantMuted
                    }"
                    [isVideoOn]="!localParticipantCameraOff"
                    [enableRemoveParticipant]="false"
                >
                </app-participant-info>
            </div>

            <ng-container *ngFor="let participant of participantsStreams">
                <div
                    class="participants__block"
                    (click)="selectParticipantHandler(participant)"
                >
                    <camera
                        [localVideoStream]="participant.videoStreams[0]"
                        *ngIf="participant.videoStreams[0].isAvailable"
                    ></camera>
                    <app-participant-info
                        [participantInfo]="participant"
                        [isVideoOn]="participant.videoStreams[0].isAvailable"
                        [enableRemoveParticipant]="
                            userDetails.userType !== Constants.TYPE_PATIENT
                        "
                        (removeParticipant)="removeParticipant($event)"
                        (openInfo)="openInfo($event)"
                    ></app-participant-info>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div
        class="modal"
        [ngClass]="{ 'fade show': openConfirmModal }"
        tabindex="-1"
        role="dialog"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-body fs-15 text-left">
                    <p>
                        Are you sure you want to leave this telehealth meeting?
                    </p>
                    <p>
                        Click <b>‘Confirm’</b> to leave the meeting and
                        <b>‘Cancel’</b> to return to the meeting.
                    </p>
                    <div class="mt-3">
                        <button
                            type="button"
                            class="btn btn-danger mx-1 w-25"
                            (click)="leaveMeeting()"
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary float-right mx-1 w-25"
                            data-dismiss="modal"
                            (click)="openConfirmModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" *ngIf="openConfirmModal"></div>

    <div
        class="modal"
        [ngClass]="{ 'fade show': openRemoveParticipantConfirmModal }"
        tabindex="-1"
        role="dialog"
    >
        <div
            class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-body fs-15 text-left">
                    <p>Are you sure you want to remove this participant?</p>
                    <div class="mt-3">
                        <button
                            type="button"
                            class="btn btn-danger mx-1 w-25"
                            (click)="removeParticipantConfirmHandler()"
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary float-right mx-1 w-25"
                            data-dismiss="modal"
                            (click)="openRemoveParticipantConfirmModal = false"
                        >
                            No
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="modal"
        [ngClass]="{ 'fade show': info }"
        tabindex="-1"
        role="dialog"
    >
        <div
            class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ariaCancelModal">
                        Browser Info
                    </h5>
                    <button
                        type="button"
                        class="btn btn-default"
                        data-bs-dismiss="modal"
                        (click)="openInfo()"
                        aria-label="Close"
                    >
                        X
                    </button>
                </div>
                <div class="modal-body fs-15 text-left">
                    <div *ngIf="participantToDisplayBrowserInfo">
                        <p
                            [innerHtml]="
                                participantToDisplayBrowserInfo['displayName']
                            "
                        ></p>
                        <p
                            [innerHtml]="utilsService.browser(participantToDisplayBrowserInfo.userAgent)"
                        ></p>
                        <!-- <p
                            [innerHtml]="
                                utilsService.browser(
                                    participantToDisplayBrowserInfo[
                                        '_telemetryLogManager'
                                    ]['_sdkUserAgent']
                                )
                            "
                        ></p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
