import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title: string = 'DigitalWorkerBees';
  // loadingDataImg: boolean = false;
  constructor(private router: Router) {
    console.log('Application started::');
    // router.events.subscribe(event => {
    //     this.navigationInterceptor(event);
    // });

    // this.grabTheTrackId();
    
    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('event', 'G-8TW4D47KR9', {
        page_path: event.urlAfterRedirects
      })
    })
    /** END : Code to Track Page View  using gtag.js */

    //Add dynamic title for selected pages - Start
    // router.events.subscribe(event => {
    //   if(event instanceof NavigationEnd) {
    //     const title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
    //     // titleService.setTitle(title);
    //   }
    // });
    //Add dynamic title for selected pages - End
  }
  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  // getTitle(state, parent) {
  //   const data = [];
  //   if(parent && parent.snapshot.data && parent.snapshot.data.title) {
  //     data.push(parent.snapshot.data.title);
  //   }

  //   if(state && parent) {
  //     data.push(... this.getTitle(state, state.firstChild(parent)));
  //   }
  //   return data;
  // }

  // Shows and hides the loading spinner during RouterEvent changes
//   navigationInterceptor(event: any): void {
//     //Triggered When the navigation starts
//     if (event instanceof NavigationStart) {
//       this.loadingDataImg = true;
//     }
//     //Triggered When the navigation ends
//     if (event instanceof NavigationEnd) {
//       this.loadingDataImg = false;
//     }

//     // Set loading state to false in both of the below events to hide the spinner in case a request fails
//     if (event instanceof NavigationCancel) {
//       this.loadingDataImg = false;
//     }
//     //Triggered When the error occurrs while navigation
//     if (event instanceof NavigationError) {
//       this.loadingDataImg = false;
//     }
//   }


  // grabTheTrackId() {
  //   console.log('environment.gaTrackingId:::', environment.gaTrackingId);
  //   let customGtagScriptEle: HTMLScriptElement = document.createElement('script');
  //   customGtagScriptEle.async = true;
  //   customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
  //   document.head.prepend(customGtagScriptEle);
  //   gtag('config', environment.gaTrackingId, { send_page_view: false });
  // }

}