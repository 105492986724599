import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioDeviceInfo, LocalVideoStream, VideoDeviceInfo } from '@azure/communication-calling';
import { MeetingInfo } from 'src/app/models/Participants';
import { ApiService } from 'src/app/services/api.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { DeviceManagerService } from 'src/app/services/device-manager.service';
import { StorageService } from 'src/app/services/storage.service';
import { v1 as createGUID } from 'uuid';



@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.css']
})
export class HomeScreenComponent implements OnInit {
  private meetingId: string | null = null;
  public user: string | null = null;
  public userType: string = '';
  public participantType: string = 'c';
  public cameraDevices: VideoDeviceInfo[] = [];
  public microphoneDevices: AudioDeviceInfo[] = [];
  public speakerDevices: AudioDeviceInfo[] = [];
  public loading: boolean = true;
  public connecting: boolean = false;
  public storeLocalStream: LocalVideoStream;
  public mediaConsent: boolean = false;
  public meetingInfo?: MeetingInfo;
  public showError: boolean = false;
  constructor(private route: ActivatedRoute,
    private deviceManagerService: DeviceManagerService,
    private connectionService: ConnectionService,
    private storageService: StorageService,
    private router: Router,
    private apiService: ApiService) {
      localStorage.removeItem('renalIQVideoUI_userDetails');
      localStorage.removeItem('renalIQVideoUI_meetingId');
    }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((params) => {
      const { meetingId, userType } = params;
      this.meetingId = meetingId;
      this.userType = userType;
      this.storageService.setMeetingId(meetingId);
      this.getMeetingInfo();
    });
  }
  private addSubscriptions(): void {
    this.storageService.getLocalStream().subscribe((localStream) => {
      this.storeLocalStream = localStream;
    })
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then((stream) => {
        if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
          this.mediaConsent = true;
          this.updateSettings();
        } else {
          this.loading = false;
          this.mediaConsent = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.mediaConsent = false;
      });
  }
  private async updateSettings() {
    await this.deviceSettings();
    await this.createVideoStream();
    this.loading = false;
  }
  private async createVideoStream() {
    const localCameraStream = new LocalVideoStream(this.storageService.getVideoDeviceInfo());
    this.storageService.setLocalStream(localCameraStream);
  }
  private async deviceSettings(): Promise<void> {
    await this.deviceManagerService.getDeviceManager();
    try {
      this.cameraDevices = await this.deviceManagerService.getCameras();
      this.storageService.setVideoDeviceInfo(this.cameraDevices[0]);
    } catch (e) {
      this.cameraDevices = [];
    }
    try {
      this.microphoneDevices = await this.deviceManagerService.getMicrophones();
      this.storageService.setAudioDeviceInfo(this.microphoneDevices[0]);
    } catch (e) {
      this.microphoneDevices = [];
    }
    try {
      this.speakerDevices = await this.deviceManagerService.getSpeakers();
    } catch (e) {
      this.speakerDevices = [];
    }
  }
  getMeetingInfo(): void {
    this.apiService.getMeetingInfo(this.meetingId).subscribe((data: MeetingInfo) => {
      if (!data) {
        this.loading = false;
        this.showError = true;
        return;
      }
      this.showError = false;
      this.meetingInfo = data;
      this.storageService.setMeetingInfo(data);
      // this.user = this.userType === 'c' ? data.currentUserName : data.data.patientName;
      this.user = this.userType === 'c' ? data.currentUserName : data.data.patientName;
      this.storageService.setUserDetails({
        userName: this.user,
        userType: this.userType
      });
      this.addSubscriptions();
    });
  }
  async next() {
    this.connecting = true;
    const meetingId = this.userType === 'c' ? this.meetingInfo.privateMeetingId : this.meetingInfo.publicMeetingId;
    //1 get token
    const { tokenCredential, userId } = await this.connectionService.getToken(meetingId);
    this.storageService.setCommunicationUserId(userId);
    //2 create call agent
    const callAgent = await this.connectionService.createCallAgent(tokenCredential, this.user);
    //3 Register for calling events
    this.connectionService.registerToCallAgent(userId, callAgent, (value: any) => { });
    //4 Join the call
    await this.connectionService.joinGroup(callAgent, this.meetingId, this.storeLocalStream);
    // this.createVideoStream()
    this.connecting = false;
    this.router.navigate(['call']);
  }
  onSettingsChanged($event: any) {
    let type = 'CAMERA';
    if ($event.label === 'CAMERA') {
      this.storageService.setVideoDeviceInfo($event.deviceInfo);
      if (this.storeLocalStream) {
        this.storeLocalStream.switchSource($event.deviceInfo);
      }
    } else if ($event.label === 'MICROPHONE') {
      this.storageService.setAudioDeviceInfo($event.deviceInfo);
      type = 'MICROPHONE';
      this.deviceManagerService.deviceManager.selectMicrophone($event.deviceInfo);
    }
    this.apiService.saveStatistic({
      meetingId: this.meetingId,
      status: 'DeviceInfo',
      type: type,
      description: `${$event.deviceInfo}`
    })
  }
}
