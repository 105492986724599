import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RemoteParticipant } from '@azure/communication-calling';

@Component({
  selector: 'app-participant-info',
  templateUrl: './participant-info.component.html',
  styleUrls: ['./participant-info.component.css']
})
export class ParticipantInfoComponent implements OnInit {
  @Input() participantInfo: RemoteParticipant;
  @Input() isVideoOn: boolean = true;
  @Input() enableRemoveParticipant: boolean = true;
  @Output() removeParticipant = new EventEmitter<RemoteParticipant>();
  @Output() openInfo = new EventEmitter<RemoteParticipant>();
  // @Output() pinUserEvent: EventEmitter<RemoteParticipant> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {}

  // pinUserHandler(): void {
  //   this.pinUserEvent.emit(this.participantInfo);
  // }
  removeParticipantHandler($event) {
    $event.stopPropagation();
    this.removeParticipant.emit(this.participantInfo);
  }
  toggleInfo($event) {
    $event.stopPropagation();
    this.openInfo.emit(this.participantInfo);
  }
}
