import { NgModule } from '@angular/core';
import { HomeScreenComponent } from './home-screen/home-screen.component';
import { CameraScreenComponent } from './camera-screen/camera-screen.component';
import { DeviceSettingComponent } from './device-setting/device-setting.component';
import { CallScreenComponent } from './call-screen/call-screen.component';
import { ParticipantInfoComponent } from './call-screen/participant-info/participant-info.component';
import { ChatComponent } from './chat/chat.component';
import { CommonModule } from '@angular/common';
import { CommonsModule } from '../common/commons.module';
import { HeaderCallScreenComponent } from './call-screen/header-call-screen/header-call-screen.component';



@NgModule({
  declarations: [
    HomeScreenComponent,
    CameraScreenComponent,
    DeviceSettingComponent,
    CallScreenComponent,
    ParticipantInfoComponent,
    ChatComponent,
    HeaderCallScreenComponent],
  imports: [
      CommonModule,
      CommonsModule
  ],
  exports: [HomeScreenComponent, CameraScreenComponent]
})
export class VideoCallModule { }
