import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JoinMeetingNotification } from '../models/Participants';

@Injectable({
  providedIn: 'root'
})
export class NotifictionService {
  private joinMeeting: BehaviorSubject<JoinMeetingNotification> = new BehaviorSubject({
    userName: '',
    state: ''
  })
  constructor() { }
  notificationSubscriber(): Observable<JoinMeetingNotification> {
    return this.joinMeeting.asObservable();
  }
  notificationSend(value: JoinMeetingNotification): void {
    this.joinMeeting.next({...value});
  }
}
