<div class="video-chat">
    <div class="video-chat__header">
        <div class="d-flex">
            <div class="flex-fill">
                <strong>Chat</strong>
            </div>
            <div>
                <button class="btn border-0 p-0 shadow-none" type="button">
                    <svg viewBox="0 0 19 15" class="svg svg-1-5 svg-black" aria-hidden="true">
                        <use class="svg-icon" xlink:href="#svg_dots"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="d-flex">
            <div class="flex-fill">
                <img class="video-chat__header-user" src="../../../assets/img/user001.png"/>
                <img class="video-chat__header-user" src="../../../assets/img/user002.jpg"/>
                <span class="video-chat__header-user">DC</span>
                <img class="video-chat__header-user" src="../../../assets/img/user004.jpg"/>
                <img class="video-chat__header-user" src="../../../assets/img/user002.jpg"/>
                <span class="video-chat__header-info">12</span>
            </div>
            <div>
                <button class="btn border-0 p-0 shadow-none mt-1" type="button">
                    <svg viewBox="0 0 19 15" class="svg svg-1-5 svg-black" aria-hidden="true">
                        <use class="svg-icon" xlink:href="#svg_arrowdown"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="video-chat__container">
        <ul class="video-chat__list">
            <li class="video-chat__item">
                <div class="video-chat__item-icon">
                    <img src="../../../assets/img/user002.jpg" alt="Somatus"/>
                </div>
                <div class="video-chat__item-user text-truncate">Devon Hawkins</div>
                <div class="video-chat__item-text">I can't hear it well</div>
                <div class="video-chat__item-time">14:32:34</div>
            </li>
            <li class="video-chat__item is-reply">
                <div class="video-chat__item-icon">
                    <span>DH</span>
                </div>
                <div class="video-chat__item-user text-truncate">Devon Hawkins</div>
                <div class="video-chat__item-text">I can't hear it well</div>
                <div class="video-chat__item-time">14:32:34</div>
            </li>
        </ul>
    </div>
    <div class="video-chat__form">
        <div class="input-group mb-3">
            <input type="text" class="form-control border-0 shadow-none" placeholder="Message..."/>
            <button class="btn border-0 pr-0 shadow-none" type="button" id="button-addon2">
                <svg viewBox="0 0 19 20" class="svg svg-1-5 svg-black" aria-hidden="true">
                    <use class="svg-icon" xlink:href="#svg_send"></use>
                </svg>
            </button>
          </div>
    </div>
</div>