import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonDateTimeFormatter } from "./pipes/common-date-time-formatter";
import { TimePipe } from "./pipes/time.pipe";
import { USDatePipe } from "./pipes/us-date.pipe";
import { USDateTimePipe } from "./pipes/us-datetime.pipe";

@NgModule({
    declarations: [
        USDatePipe,
        USDateTimePipe,
        TimePipe,
    ],
    imports: [
        CommonModule
    ],
    providers: [
        CommonDateTimeFormatter,
    ],
    exports: [
        USDatePipe,
        USDateTimePipe,
        TimePipe,
    ]
})
export class CommonsModule { }