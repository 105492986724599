<div class="d-flex mb-2 setting-switch">
    <div class="flex-fill">
        <div class="card-box__subtitle">{{ label }}</div>
    </div>
    <!-- <div>
        <div class="custom-control custom-switch">
            <input checked type="checkbox" class="custom-control-input" id="{{ label }}">
            <label class="custom-control-label" for="{{ label }}">
                <span class="is-on">On</span>
                <span class="is-off">Off</span>
            </label>
        </div>
    </div> -->
</div>
<select class="custom-select" id="{{ id }}"
        (change)="onSettingsChanged($event.target.value)">
    <option *ngFor="let device of devices"
            [value]="device._id" [selected]="device._id === selectedId">
        {{ device._name }}
    </option>
</select>