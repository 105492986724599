import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { v1 as createGUID } from 'uuid';

@Component({
  selector: 'app-patient-consent',
  templateUrl: './patient-consent.component.html',
  styleUrls: ['./patient-consent.component.css']
})
export class PatientConsentComponent implements OnInit {
  private groupId: string | null = null;
  public isRedirect = true;
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (environment.production) {
      window.open('https://somatus.com/', '_self');
    } else {
      this.isRedirect = false;
    }
  }
  getGroupIdFromUrl = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('groupId');
  };
  getGroupId = (): string => {
    if (this.groupId) return this.groupId;
    const uriGid = this.getGroupIdFromUrl();
    const gid = uriGid == null || uriGid === '' ? createGUID() : uriGid;
    console.log(gid);
    this.groupId = gid;
    return gid;
  };
  accept() {
    this.router.navigate([`join/c/${this.getGroupId()}`]);
  }

}
