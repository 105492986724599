<div class="section__header">
    <div class="d-flex">
        <div class="d-flex flex-fill">
            <div>
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus" />
            </div>
            <div class="flex-fill d-none d-md-flex" *ngIf="userDetails.userType !== Constants.TYPE_PATIENT">
                <div class="section__header-text">
                    Telehealth <span class="opacity-075">with</span> {{meetingInfo.data.patientName}}
                </div>
            </div>
        </div>
        <div>
            <div class="section__header-timer" *ngIf="userDetails.userType !== Constants.TYPE_PATIENT">
                <div class="opacity-075">
                    <svg viewBox="0 0 512 512" class="svg svg-1-75 svg-white align-top" aria-hidden="true">
                        <use class="svg-icon" xlink:href="#svg_timer"></use>
                    </svg>
                    <strong class="section__header-timer-info">{{durationLeft.hours}}<span
                            class="mx-05">:</span>{{durationLeft.minutes}}<span
                            class="mx-05">:</span>{{durationLeft.seconds}}</strong>
                    <div class="text-uppercase">{{meetingExtended ? 'Time Extended': 'Duration Left'}}</div>
                </div>
                <div class="popover fade bs-popover-bottom">
                    <div class="arrow"></div>
                    <div class="popover-body py-3">
                        <div class="fs-12 text-uppercase text-primary">Appointment Start Time</div>
                        <div class="fs-18">{{meetingInfo.scheduleStartDateTime | usDateTime}}</div>
                        <hr class="my-2 opacity-05" />
                        <div class="fs-12 text-uppercase text-primary">Appointment End Time</div>
                        <div class="fs-18">{{meetingInfo.scheduleEndDateTime | usDateTime}}</div>
                        <hr class="my-2 opacity-05" />
                        <div class="fs-12 text-uppercase text-primary">Planned Duration</div>
                        <div class="fs-18">{{meetingInfo.duration}}:00</div>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="section__header-btn" [ngClass]="{'is-active': settings}"
                (click)="toggleSettings()">
                <svg viewBox="0 0 478.703 478.703" class="svg svg-1-5 svg-white align-top" aria-hidden="true">
                    <use class="svg-icon" xlink:href="#svg_setting"></use>
                </svg>
                <div class="mt-1">Settings</div>
            </a>
        </div>
    </div>
</div>