<span>
    <span class="video-section__user-text" *ngIf="!isVideoOn">
        <div class="user-icon user-icon--sm">
            <svg
                viewBox="0 0 489 489"
                aria-hidden="true"
                class="svg svg-secondary opacity-05"
            >
                <use xlink:href="#svg_user" class="svg-icon"></use>
            </svg>
        </div>
    </span>
    <div class="user-info">
        <div class="user-info__mute mr-1">
            <svg
                viewBox="0 0 42.67 64"
                class="svg svg-1-125 svg-white"
                aria-hidden="true"
                *ngIf="!participantInfo.isMuted"
            >
                <use class="svg-icon" xlink:href="#svg_mike"></use>
            </svg>
            <svg
                viewBox="0 0 28 27"
                class="svg svg-1-125 svg-white"
                aria-hidden="true"
                *ngIf="participantInfo.isMuted"
            >
                <use class="svg-icon" xlink:href="#svg_mute"></use>
            </svg>
        </div>
        <div class="d-inline-block text-truncate align-top mw-7-75">
            {{ participantInfo.displayName }}
        </div>
        <div
            class="user-info__remove-participant"
            *ngIf="enableRemoveParticipant"
        >
            <svg
                viewBox="0 0 328 328"
                class="svg svg-1 svg-white"
                aria-hidden="true"
                (click)="removeParticipantHandler($event)"
            >
                <use
                    class="svg-icon"
                    xlink:href="#svg_remove-participant"
                ></use>
            </svg>
        </div>
    </div>
    <div
        class="user-info__details"
        (click)="toggleInfo($event)"
        *ngIf="enableRemoveParticipant"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            class="svg svg-1 svg-white align-top"
            aria-hidden="true"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.92,5.32v0A.92.92,0,1,0,7,4.37v0A.92.92,0,0,0,7.92,5.32ZM8.85,6H6.55a4.58,4.58,0,0,1-.1.63,3,3,0,0,1-.2.67h1.3v3.53H6.25v1.3H9.57v-1.3H8.85ZM13,3.05A7,7,0,1,0,13,13,7,7,0,0,0,13,3.05ZM12,12A5.6,5.6,0,0,1,4,12,5.61,5.61,0,0,1,4,4,5.6,5.6,0,0,1,12,4,5.61,5.61,0,0,1,12,12Z"
            />
        </svg>
    </div>
</span>
