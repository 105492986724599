<section class="section">
 
    <header class="section__header">
        <div class="d-flex">
            <div>
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
            </div>
        </div>
    </header>
    <div class="section__content" [ngClass]="{'align-items-center': connecting || loading}">

        <div class="container">
            <div *ngIf="!connecting && !showError && !loading" class="row mb-3">
                <div class="col-12 d-none d-lg-block col-lg-4"  *ngIf="userType === 'c'">
                    <div class="card-box">
                        <div class="mb-3">
                            <div class="fs-14 text-uppercase opacity-075">Telehealth Id</div>
                            <div class="fs-18">{{meetingInfo?.privateMeetingId}}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fs-14 text-uppercase opacity-075">Appointment Date</div>
                            <div class="fs-18">{{meetingInfo?.scheduleStartDateTime | usDate}}</div>
                        </div>
                        <div class="mb-3">
                            <div class="fs-14 text-uppercase opacity-075">Appointment Time</div>
                            <div class="fs-18">{{meetingInfo?.scheduleStartDateTime | time}}</div>
                        </div>
                        <div>
                            <div class="fs-14 text-uppercase opacity-075">Appointment Duration</div>
                            <div class="fs-18">{{meetingInfo?.duration}} Minutes</div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-12 col-lg-8 m-auto" >
                    <div class="card-box">
                        <div>
                            <div class="card-box__title mb-3"><span class="opacity-075">WELCOME</span> {{user}}</div>
                            <hr class="opacity-05 d-none d-md-block"/>
                            <div class="row" *ngIf="mediaConsent">
                                <div class="col-12 col-md-5">
                                    <div class="card-box__border mb-3 mb-lg-0">
                                        <div class="user-icon user-icon--sm">
                                            <svg viewBox="0 0 489 489" class="svg svg-secondary opacity-05" aria-hidden="true">
                                                <use class="svg-icon" xlink:href="#svg_user"></use>
                                            </svg>
                                        </div>
                                        <camera #camera [localVideoStream]="storeLocalStream"></camera>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <form class="form">
                                        <div class="form-group" *ngIf="cameraDevices.length > 0">
                                            <app-device-setting #videoSelect
                                                [label]="'CAMERA'" [devices]="cameraDevices"
                                                (settingsChanged)="onSettingsChanged($event)"></app-device-setting>
                                        </div>
                                        <hr class="opacity-05"/>
                                        <div class="form-group" *ngIf="microphoneDevices.length > 0">
                                            <app-device-setting [label]="'MICROPHONE'" [devices]="microphoneDevices"
                                                (settingsChanged)="onSettingsChanged($event)"></app-device-setting>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="alert alert-warning" role="alert" *ngIf="!mediaConsent">
                                <div class="d-flex">
                                    <div>
                                        <svg viewBox="-0.5 0.5 42 42" class="svg svg-1 svg-warning align-text-top mr-2" aria-hidden="true">
                                            <use class="svg-icon" xlink:href="#svg_alert-fill"></use>
                                        </svg>
                                    </div>
                                    <div class="flex-fill">
                                        Media permission denied. Allow media to join Telehealth.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!connecting && !showError && !loading"
                class="text-center text-md-right m-auto" [ngClass]="{'col-lg-8 p-0': userType === 'p'}">
                <button type="submit" 
                    class="btn btn-lg btn-info px-4 px-sm-5"
                    [disabled]="!mediaConsent"
                    (click)="next()">
                    Join Telehealth
                </button>
            </div>
            <div class="text-center" *ngIf="loading">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                </div>
                <div class="mt-2">
                    <span class="text-primary">Loading...</span>
                </div>
            </div>
            <div class="text-center" *ngIf="connecting">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                </div>
                <div class="mt-2">
                    <span class="text-primary">Please wait..</span>
                    <div class="fs-14 opacity-05">Connecting with {{userType === 'p'? 'Somatus Care Member': 'Patient'}}.</div>
                </div>
            </div>
            <div class="text-center" *ngIf="showError">
                <div class="mt-2">
                    <span class="text-primary">You don't have any meeting scheduled.</span>
                    <div class="fs-14 opacity-05">Please check with Somatus CareTeam for your meeting time.</div>
                </div>
            </div>
        </div>
    </div>
</section>